import PropTypes from 'prop-types';
import OrderContext from './OrderContext';
import { GetData, PostData, PutData } from '../../services';
import jwt_decode from 'jwt-decode';

const OrderState = (props) => {
    // const user = localStorage.getItem('BulkViews');

    const addOrder = async (data) => {
        const res = await PostData(true, '/orders', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getOrders = async (data) => {
        const { token } = JSON.parse(localStorage.getItem('BulkViews'));
        const { id } = jwt_decode(token.access_token);
        const res = await PostData(true, '/orders/order-list', { id: id, data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const updateOrder = async (data) => {
        const res = await PutData(true, '/orders/' + data.id, data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getSingleOrderDetails = async ({ id }) => {
        const res = await GetData(true, '/orders/' + id);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const countOrders = async () => {
        const { token } = JSON.parse(localStorage.getItem('BulkViews'));
        const { id } = jwt_decode(token.access_token);
        const res = await PostData(true, '/orders/order-count', { id: id });
        if (res.status === 200) {
            return res.payload;
        }
    };

    return (
        <OrderContext.Provider value={{ addOrder, getOrders, updateOrder, getSingleOrderDetails, countOrders }}>
            {props.children}
        </OrderContext.Provider>
    );
};

OrderState.propTypes = {
    children: PropTypes.node
};

export default OrderState;
