import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AuthContext from './AuthContext';
import { GetData, PostData, PutData, PutFormData } from '../../services';
import { useLocalStorage } from './useLocalStorage';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

const AuthState = (props) => {
    const [user, setUser] = useLocalStorage('BulkViews', null);
    const [userDetails, setUserDetails] = useState([]);
    // const tokenTime = user ? user.token.accessTokenExpiresIn : '';
    // const [tokenExpirytime, setTokenExpirytime] = useState(tokenTime);

    const navigate = useNavigate();
    const addUser = async (data) => {
        const res = await PostData(false, '/users', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const updateUser = async (data, id) => {
        const res = await PutFormData(true, '/users/' + id, data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const updatePassword = async (data) => {
        const res = await PutData(true, '/users/change-password/' + data.id, data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const confirmUser = async ({ id, status }) => {
        const res = await PutData(false, '/users/updateStatus/' + id, { status });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const loginUser = async (data) => {
        const res = await PostData(false, '/users/login', data);
        if (res.status === 200) {
            setUser(res.payload.result);
            return res.payload;
        }
    };

    const logout = async () => {
        localStorage.removeItem('BulkViews');
        navigate('/', { replace: true });
        setUser(null);
    };

    const getUserDetails = async ({ id }) => {
        const res = await GetData(true, '/users/' + id);
        if (res.status === 200) {
            setUserDetails(res.payload);
            if (res.payload.result.blockStatus == '0') {
                localStorage.removeItem('BulkViews');
                window.location.href = '/';
            } else {
                return res.payload;
            }
        }
    };

    const refreshToken = async (data) => {
        const res = await PostData(true, '/users/refresh-token', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const forgotPassword = async (data) => {
        const res = await PostData(false, '/users/forgot-password', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const verifyResetPasswordLink = async (id, token) => {
        const res = await GetData(false, '/users/reset-password/' + id + '/' + token);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const resetPassword = async (id, token, data) => {
        const res = await PostData(false, '/users/reset-password/' + id + '/' + token, data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getDashboardDetails = async () => {
        const res = await PostData(true, '/users/get-dashboard-details');
        if (res.status === 200) {
            return res.payload;
        }
    };

    useEffect(() => {
        (async () => {
            if (user) {
                const token = user ? user.token.access_token : '';
                const { id } = jwt_decode(token);
                await getUserDetails({ id });
            }
        })();
    }, [user]);

    // useEffect(() => {
    //     if (user && tokenExpirytime) {
    //         const interval = (async () => {
    //             const timeNow = Math.floor(Date.now() / 1000);
    //             if (timeNow >= tokenExpirytime) {
    //                 try {
    //                     const res = await refreshToken({ refresh_token: user.token.refresh_token });
    //                     if (res.status === true) {
    //                         setTokenExpirytime(res.token.accessTokenExpiresIn);
    //                         localStorage.setItem(
    //                             'BulkViews',
    //                             JSON.stringify({
    //                                 token: res.token
    //                             })
    //                         );
    //                     }
    //                 } catch (error) {
    //                     if (error) {
    //                         logout();
    //                     }
    //                 }
    //             }
    //         });
    //         interval();
    //     }
    // }, [tokenExpirytime, user]);

    // useEffect(() => {
    //     if (user && tokenExpirytime) {
    //         const interval = setInterval(async () => {
    //             const timeNow = Math.floor(Date.now() / 1000);
    //             if (timeNow >= tokenExpirytime) {
    //                 try {
    //                     const res = await refreshToken({ refresh_token: user.token.refresh_token });
    //                     if (res.status === true) {
    //                         setTokenExpirytime(res.token.accessTokenExpiresIn);
    //                         localStorage.setItem(
    //                             'BulkViews',
    //                             JSON.stringify({
    //                                 token: res.token
    //                             })
    //                         );
    //                     }
    //                 } catch (error) {
    //                     if (error) {
    //                         logout();
    //                     }
    //                 }
    //             }
    //         }, 1000);
    //         return () => clearInterval(interval);
    //     }
    // }, [tokenExpirytime, user]);

    return (
        <AuthContext.Provider
            value={{
                userDetails,
                user,
                addUser,
                confirmUser,
                loginUser,
                logout,
                refreshToken,
                updateUser,
                getUserDetails,
                updatePassword,
                forgotPassword,
                verifyResetPasswordLink,
                resetPassword,
                getDashboardDetails
            }}
        >
            {props.children}
        </AuthContext.Provider>
    );
};

AuthState.propTypes = {
    children: PropTypes.node
};

export default AuthState;
