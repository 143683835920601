import PropTypes from 'prop-types';
import ProductsContext from './ProductsContext';
import { GetData } from '../../services';

const ProductsState = (props) => {
    const getProducts = async () => {
        const res = await GetData(true, '/products');
        if (res.status === 200) {
            return res.payload;
        }
    };
    const getRecomendedsProduct = async () => {
        const res = await GetData(true, '/products/recomended');
        if (res.status === 200) {
            return res.payload;
        }
    };
    return <ProductsContext.Provider value={{ getProducts, getRecomendedsProduct }}>{props.children}</ProductsContext.Provider>;
};

ProductsState.propTypes = {
    children: PropTypes.node
};

export default ProductsState;
