import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// pages routing
const Dashboard = Loadable(lazy(() => import('views/pages/dashboard')));
const AddNewOrder = Loadable(lazy(() => import('views/pages/add-new-order')));
const AddFunds = Loadable(lazy(() => import('views/pages/add-funds')));
const OrderHistory = Loadable(lazy(() => import('views/pages/orders')));
const Profile = Loadable(lazy(() => import('views/pages/profile')));
const OrderSuccess = Loadable(lazy(() => import('views/pages/add-new-order/Success')));
const WalletSuccess = Loadable(lazy(() => import('views/pages/add-funds/Success')));
const FAQ = Loadable(lazy(() => import('views/pages/faq')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

import CategoriesState from '../context/categories/CategoriesState';
import ProductsState from '../context/products/ProductsState';
import OrdersState from '../context/orders/OrderState';

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    element: <MainLayout />,
    children: [
        {
            path: '/dashboard',
            element: <Dashboard />
        },
        {
            path: 'add-new-campaign',
            element: (
                <CategoriesState>
                    <ProductsState>
                        <OrdersState>
                            <AddNewOrder />
                        </OrdersState>
                    </ProductsState>
                </CategoriesState>
            )
        },
        {
            path: 'add-funds',
            element: <AddFunds />
        },
        {
            path: 'my-campaigns',
            element: (
                <OrdersState>
                    <OrderHistory />
                </OrdersState>
            )
        },
        {
            path: 'account',
            element: (
                <OrdersState>
                    <Profile />
                </OrdersState>
            )
        },
        {
            path: 'faq',
            element: (
                <OrdersState>
                    <FAQ />
                </OrdersState>
            )
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <UtilsTypography />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <UtilsColor />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <UtilsShadow />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <UtilsTablerIcons />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <UtilsMaterialIcons />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <SamplePage />
        },
        {
            path: 'campaign/success/:id',
            element: (
                <OrdersState>
                    <OrderSuccess />
                </OrdersState>
            )
        },
        {
            path: 'wallet/success/:id',
            element: <WalletSuccess />
        }
    ]
};

export default MainRoutes;
