import PropTypes from 'prop-types';
import CategoriesContext from './CategoriesContext';
import { GetData } from '../../services';

const CategoriesState = (props) => {
    const getCategories = async () => {
        const res = await GetData(true, '/categories');
        if (res.status === 200) {
            return res.payload;
        }
    };
    return <CategoriesContext.Provider value={{ getCategories }}>{props.children}</CategoriesContext.Provider>;
};

CategoriesState.propTypes = {
    children: PropTypes.node
};

export default CategoriesState;
