import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import WalletContext from './WalletContext';
import { PostData, PutData, GetData } from '../../services';
import jwt_decode from 'jwt-decode';

const WalletState = (props) => {
    const user = localStorage.getItem('BulkViews');
    const [wallet, setWallet] = useState([]);

    const addWallet = async (data) => {
        const res = await PostData(true, '/wallets', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getWallets = async () => {
        const { token } = JSON.parse(localStorage.getItem('BulkViews'));
        const { id } = jwt_decode(token.access_token);
        const res = await PostData(true, '/wallets/wallet-list', { id: id });
        if (res.status === 200) {
            setWallet(res.payload);
            return res.payload;
        }
    };

    const updateCart = async (data) => {
        const res = await PutData(true, '/carts/' + data.id, data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getTransction = async (data) => {
        const { token } = JSON.parse(localStorage.getItem('BulkViews'));
        const { id } = jwt_decode(token.access_token);
        const res = await PostData(true, '/wallets/transaction-list', { id: id, data });
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getTransctionDetails = async ({ id }) => {
        const res = await GetData(true, '/wallets/transaction-details/' + id);
        if (res.status === 200) {
            return res.payload;
        }
    };

    useEffect(() => {
        if (user != 'null') {
            getWallets();
        }
    }, [user]);

    return (
        <WalletContext.Provider value={{ addWallet, wallet, getWallets, updateCart, getTransction, getTransctionDetails }}>
            {props.children}
        </WalletContext.Provider>
    );
};

WalletState.propTypes = {
    children: PropTypes.node
};

export default WalletState;
