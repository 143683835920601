import { useContext } from 'react';
import PropTypes from 'prop-types';
// material-ui
import { styled } from '@mui/material/styles';
import { Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useTheme } from '@mui/material/styles';
// styles
const CardStyle = styled(Card)(({ theme }) => ({
    background: theme.palette.warning.light,
    marginBottom: '16px',
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
        content: '""',
        position: 'absolute',
        width: '200px',
        height: '200px',
        border: '19px solid ',
        borderColor: theme.palette.warning.main,
        borderRadius: '50%',
        top: '65px',
        right: '-150px'
    },
    '&:before': {
        content: '""',
        position: 'absolute',
        width: '200px',
        height: '200px',
        border: '3px solid ',
        borderColor: theme.palette.warning.main,
        borderRadius: '50%',
        top: '145px',
        right: '-70px'
    },
    '& small': {
        color: '#000'
    }
}));
import { MENU_OPEN } from 'store/actions';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import WalletContext from '../../../../context/wallets/WalletContext';

// ==============================|| PROFILE MENU - UPGRADE PLAN CARD ||============================== //

const UpgradePlanCard = ({ handleClose }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleListItemClick = (event, index, route = '') => {
        dispatch({ type: MENU_OPEN, id: '' });
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };

    const walletsContext = useContext(WalletContext);
    const { wallet } = walletsContext;

    return (
        <>
            <CardStyle>
                <CardContent>
                    <Grid container direction="column" spacing={2}>
                        <Grid item>
                            <Typography variant="h4">Finance</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="h4" sx={{ color: theme.palette.primary.main }}>
                                ${wallet?.result?.balance.toFixed(2)} <small>Balance</small>
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Stack direction="row">
                                <AnimateButton>
                                    <Button
                                        variant="contained"
                                        color="warning"
                                        sx={{ boxShadow: 'none' }}
                                        onClick={(event) => handleListItemClick(event, 1, '/add-funds')}
                                    >
                                        Add Funds
                                    </Button>
                                </AnimateButton>
                            </Stack>
                        </Grid>
                    </Grid>
                </CardContent>
            </CardStyle>
        </>
    );
};

UpgradePlanCard.propTypes = {
    handleClose: PropTypes.func
};

export default UpgradePlanCard;
