import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CartContext from './CartContext';
import { PostData, PutData } from '../../services';
import jwt_decode from 'jwt-decode';

const CartState = (props) => {
    const user = localStorage.getItem('BulkViews');
    const [cartList, setCartList] = useState([]);

    const addCart = async (data) => {
        const res = await PostData(true, '/carts', data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    const getCarts = async () => {
        const { token } = JSON.parse(localStorage.getItem('BulkViews'));
        const { id } = jwt_decode(token.access_token);
        const res = await PostData(true, '/carts/cart-list', { id: id });
        if (res.status === 200) {
            setCartList(res.payload);
            return res.payload;
        }
    };

    const updateCart = async (data) => {
        const res = await PutData(true, '/carts/' + data.id, data);
        if (res.status === 200) {
            return res.payload;
        }
    };

    useEffect(() => {
        if (user != 'null') {
            getCarts();
        }
    }, [user]);

    return <CartContext.Provider value={{ addCart, cartList, getCarts, updateCart }}>{props.children}</CartContext.Provider>;
};

CartState.propTypes = {
    children: PropTypes.node
};

export default CartState;
