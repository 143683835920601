// assets
import { IconDashboard, IconShoppingCartPlus } from '@tabler/icons';
import HistoryIcon from '@mui/icons-material/History';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
// constant
const icons = {
    IconDashboard,
    IconShoppingCartPlus,
    HistoryIcon,
    AccountBalanceWalletOutlinedIcon,
    ConfirmationNumberOutlinedIcon,
    HelpOutlineIcon
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'add-new-campaign',
            title: 'New Campaign',
            type: 'item',
            url: '/add-new-campaign',
            icon: icons.IconShoppingCartPlus,
            breadcrumbs: false
        },
        {
            id: 'my-campaigns',
            title: 'My Campaigns',
            type: 'item',
            url: '/my-campaigns',
            icon: icons.HistoryIcon,
            breadcrumbs: false
        },
        {
            id: 'add-funds',
            title: 'Add Funds',
            type: 'item',
            url: '/add-funds',
            icon: icons.AccountBalanceWalletOutlinedIcon,
            breadcrumbs: false
        },
        {
            id: 'faq',
            title: 'FAQ',
            type: 'item',
            url: '/faq',
            icon: icons.HelpOutlineIcon,
            breadcrumbs: false
        }
    ]
};

export default pages;
